/**
 * Syntax highlighting styles
 */
.highlight {
  background: #fff;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: #eef;
  }

  .c     { color: var(--color-hl-comment-1); font-style: italic } // Comment
  .err   { color: var(--color-hl-error-2); background-color: var(--color-hl-error-2-bg) } // Error
  .k     { font-weight: bold } // Keyword
  .o     { font-weight: bold } // Operator
  .cm    { color: var(--color-hl-comment-1); font-style: italic } // Comment.Multiline
  .cp    { color: var(--color-hl-comment-2); font-weight: bold } // Comment.Preproc
  .c1    { color: var(--color-hl-comment-1); font-style: italic } // Comment.Single
  .cs    { color: var(--color-hl-comment-2); font-weight: bold; font-style: italic } // Comment.Special
  .gd    { color: var(--color-hl-del-1); background-color: var(--color-hl-del-1-bg) } // Generic.Deleted
  .gd .x { color: var(--color-hl-del-2); background-color: var(--color-hl-del-2-bg) } // Generic.Deleted.Specific
  .ge    { font-style: italic } // Generic.Emph
  .gr    { color: var(--color-hl-error-1) } // Generic.Error
  .gh    { color: var(--color-hl-comment-2) } // Generic.Heading
  .gi    { color: var(--color-hl-add-1); background-color: var(--color-hl-add-1-bg) } // Generic.Inserted
  .gi .x { color: var(--color-hl-add-2); background-color: var(--color-hl-add-2-bg) } // Generic.Inserted.Specific
  .go    { color: var(--color-hl-output-1) } // Generic.Output
  .gp    { color: var(--color-hl-prompt-1) } // Generic.Prompt
  .gs    { font-weight: bold } // Generic.Strong
  .gu    { color: var(--color-hl-subheading-1) } // Generic.Subheading
  .gt    { color: var(--color-hl-error-1) } // Generic.Traceback
  .kc    { font-weight: bold } // Keyword.Constant
  .kd    { font-weight: bold } // Keyword.Declaration
  .kp    { font-weight: bold } // Keyword.Pseudo
  .kr    { font-weight: bold } // Keyword.Reserved
  .kt    { color: var(--color-hl-type-1); font-weight: bold } // Keyword.Type
  .m     { color: var(--color-hl-literal-num) } // Literal.Number
  .s     { color: var(--color-hl-literal-str) } // Literal.String
  .na    { color: var(--color-hl-name-1) } // Name.Attribute
  .nb    { color: var(--color-hl-name-2) } // Name.Builtin
  .nc    { color: var(--color-hl-type-1); font-weight: bold } // Name.Class
  .no    { color: var(--color-hl-name-1) } // Name.Constant
  .ni    { color: var(--color-hl-name-3) } // Name.Entity
  .ne    { color: var(--color-hl-name-5); font-weight: bold } // Name.Exception
  .nf    { color: var(--color-hl-name-5); font-weight: bold } // Name.Function
  .nn    { color: var(--color-hl-prompt-1) } // Name.Namespace
  .nt    { color: var(--color-hl-name-4) } // Name.Tag
  .nv    { color: var(--color-hl-name-1) } // Name.Variable
  .ow    { font-weight: bold } // Operator.Word
  .w     { color: var(--color-hl-whitespace) } // Text.Whitespace
  .mf    { color: var(--color-hl-literal-num) } // Literal.Number.Float
  .mh    { color: var(--color-hl-literal-num) } // Literal.Number.Hex
  .mi    { color: var(--color-hl-literal-num) } // Literal.Number.Integer
  .mo    { color: var(--color-hl-literal-num) } // Literal.Number.Oct
  .sb    { color: var(--color-hl-literal-str) } // Literal.String.Backtick
  .sc    { color: var(--color-hl-literal-str) } // Literal.String.Char
  .sd    { color: var(--color-hl-literal-str) } // Literal.String.Doc
  .s2    { color: var(--color-hl-literal-str) } // Literal.String.Double
  .se    { color: var(--color-hl-literal-str) } // Literal.String.Escape
  .sh    { color: var(--color-hl-literal-str) } // Literal.String.Heredoc
  .si    { color: var(--color-hl-literal-str) } // Literal.String.Interpol
  .sx    { color: var(--color-hl-literal-str) } // Literal.String.Other
  .sr    { color: var(--color-hl-literal-regex) } // Literal.String.Regex
  .s1    { color: var(--color-hl-literal-str) } // Literal.String.Single
  .ss    { color: var(--color-hl-literal-symbol) } // Literal.String.Symbol
  .bp    { color: var(--color-hl-comment-2) } // Name.Builtin.Pseudo
  .vc    { color: var(--color-hl-name-1) } // Name.Variable.Class
  .vg    { color: var(--color-hl-name-1) } // Name.Variable.Global
  .vi    { color: var(--color-hl-name-1) } // Name.Variable.Instance
  .il    { color: var(--color-hl-literal-num) } // Literal.Number.Integer.Long
}
