:root {
      --color-grey:               #828282;
      --color-grey-light:         #e8e8e8;
      --color-grey-dark:          #424242;

      //
      // base colors
      //
      --color-default-back:       #fdfdfd;
      --color-default-text:       #111;
      --color-bold-text:          #000;

      --color-link-text:          #2a7ae2;
      --color-link-visited-text:  #1756a9;  // darken(link-text, 15%);

      --color-blockquote-text:    var(--color-grey);
      --color-blockquote-border:  var(--color-grey-light);

      --color-code-back:          #eef;
      --color-code-border:        var(--color-grey-light);

      --color-table-text:         #3f3f3f;  // lighten(default-text, 18%)
      --color-table-border:       var(--color-grey-light);
      --color-table-separate:     #c9c9c9;  // darken(grey-light, 12%)
      --color-table-head-back:    #f0f0f0;  // lighten(grey-light, 3%)
      --color-table-even-back:    #f7f7f7;  // lighten(grey-light, 6%)

      --color-social-icon-fill:   var(--color-grey);

      //
      // layout colors
      //
      --color-header-top:         var(--color-grey-dark);
      --color-header-bottom:      var(--color-grey-light);

      --color-title-text:         var(--color-grey-dark);
      --color-nav-border:         var(--color-grey-light);
      --color-nav-icon-fill:      var(--color-grey-dark);

      --color-footer-top:         var(--color-grey-light);
      --color-footer-text:        var(--color-grey);

      --color-meta-text:          var(--color-grey);

      //
      // syntax highlight
      //
      --color-hl-comment-1:       #998;
      --color-hl-comment-2:       #999;
      --color-hl-error-1:         #a00;
      --color-hl-error-2:         #a61717;
      --color-hl-error-2-bg:      #e3d2d2;
      --color-hl-output-1:        #888;
      --color-hl-prompt-1:        #555;
      --color-hl-subheading-1:    #aaa;
      --color-hl-type-1:          #458;
      --color-hl-literal-num:     #099;
      --color-hl-literal-str:     #d14;
      --color-hl-literal-regex:   #009926;
      --color-hl-literal-symbol:  #990073;
      --color-hl-name-1:          #008080;
      --color-hl-name-2:          #0086B3;
      --color-hl-name-3:          #800080;
      --color-hl-name-4:          #000080;
      --color-hl-name-5:          #900;
      --color-hl-whitespace:      #bbb;
      --color-hl-add-1:           #000;
      --color-hl-add-1-bg:        #dfd;
      --color-hl-add-2:           #000;
      --color-hl-add-2-bg:        #afa;
      --color-hl-del-1:           #000;
      --color-hl-del-1-bg:        #fdd;
      --color-hl-del-2:           #000;
      --color-hl-del-2-bg:        #faa;
}
