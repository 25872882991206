@media screen and (prefers-color-scheme: dark) {
   :root {
      --color-normal:             #eee;
      --color-bright:             #fff;
      --color-reduced-1:          #aaa;
      --color-reduced-2:          #666;

      //
      // base colors
      //
      --color-default-back:       #222;
      --color-default-text:       var(--color-normal);
      --color-bold-text:          var(--color-bright);

      --color-link-text:          #88f;
      --color-link-visited-text:  #bbf;

      // TODO (unused right now)
      --color-blockquote-text:    #828282;  // grey
      --color-blockquote-border:  #e8e8e8;  // grey-light

      --color-code-back:          #111;
      --color-code-border:        #333;

      // TODO (unused right now)
      --color-table-text:         var(--color-normal);
      --color-table-border:       #e8e8e8;  // grey-light
      --color-table-separate:     #c9c9c9;  // darken(grey-light, 12%)
      --color-table-head-back:    #202020;
      --color-table-even-back:    #181818;

      --color-social-icon-fill:   var(--color-reduced-1);

      //
      // layout colors
      //
      --color-header-top:         var(--color-reduced-1);
      --color-header-bottom:      var(--color-reduced-2);

      --color-title-text:         var(--color-normal);
      --color-nav-border:         var(--color-reduced-2);
      --color-nav-icon-fill:      var(--color-reduced-1);

      --color-footer-top:         var(--color-reduced-2);
      --color-footer-text:        var(--color-reduced-1);

      --color-meta-text:          var(--color-reduced-1);

      //
      // syntax highlight
      //
      // yellow is not yet converted to dark mode, apparently the
      // highlighter doesn't use those for the code snippets on my
      // site.  The comments have the light mode color.
      //
      --color-hl-comment-1:       #666;          // #998;
      --color-hl-comment-2:       #666;          // #999;
      --color-hl-error-1:         yellow;        // #a00;
      --color-hl-error-2:         #d22;          // #a61717;
      --color-hl-error-2-bg:      transparent;   // #e3d2d2;
      --color-hl-output-1:        yellow;        // #888;
      --color-hl-prompt-1:        yellow;        // #555;
      --color-hl-subheading-1:    yellow;        // #aaa;
      --color-hl-type-1:          yellow;        // #458;
      --color-hl-literal-num:     yellow;        // #099;
      --color-hl-literal-str:     #f8a;          // #d14;
      --color-hl-literal-regex:   yellow;        // #009926;
      --color-hl-literal-symbol:  yellow;        // #990073;
      --color-hl-name-1:          #6cc;          // #008080;
      --color-hl-name-2:          #6bf;          // #0086B3;
      --color-hl-name-3:          yellow;        // #800080;
      --color-hl-name-4:          #aaf;          // #000080;
      --color-hl-name-5:          #f88;          // #900;
      --color-hl-whitespace:      yellow;        // #bbb;
      --color-hl-add-1:           #0a0;          // #000;
      --color-hl-add-1-bg:        transparent;   // #dfd;
      --color-hl-add-2:           yellow;        // #000;
      --color-hl-add-2-bg:        yellow;        // #afa;
      --color-hl-del-1:           #a00;          // #000;
      --color-hl-del-1-bg:        transparent;   // #fdd;
      --color-hl-del-2:           yellow;        // #000;
      --color-hl-del-2-bg:        yellow;        // #faa;
   }
}
